// Generated by Framer (70e732d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["HANcXQxax"];

const variantClassNames = {HANcXQxax: "framer-v-lguohf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "HANcXQxax", title: o8IUMdDYJ = "Copy link", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "HANcXQxax", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-QU5IY", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-lguohf", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"HANcXQxax"} ref={ref} style={{...style}} transition={transition}><motion.div className={"framer-2xxyml"} data-framer-name={"Actions / Copy"} layoutDependency={layoutDependency} layoutId={"Mb7iyB951"} transition={transition}><SVG className={"framer-1axe1o3"} data-framer-name={"icon/content/copy_24px"} fill={"rgba(0,0,0,1)"} intrinsicHeight={13} intrinsicWidth={11} layoutDependency={layoutDependency} layoutId={"I637:264152;205:857"} svg={"<svg width=\"11\" height=\"13\" viewBox=\"0 0 11 13\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.97016 0.468292H1.42528C0.825338 0.468292 0.334473 0.959158 0.334473 1.5591V9.19479H1.42528V1.5591H7.97016V0.468292ZM9.60602 2.6519H3.60655C3.00661 2.6519 2.51574 3.14277 2.51574 3.74272V11.3784C2.51574 11.9783 3.00661 12.4692 3.60655 12.4692H9.60602C10.206 12.4692 10.6968 11.9783 10.6968 11.3784V3.74272C10.6968 3.14277 10.206 2.6519 9.60602 2.6519ZM3.60791 11.3735H9.60738V3.73782H3.60791V11.3735Z\" fill=\"#949494\"/>\n</svg>\n"} transition={transition} withExternalLayout/></motion.div><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "12px", "--framer-line-height": "16px", "--framer-text-alignment": "center"}}><motion.span style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter-Medium\", \"Inter\", sans-serif", "--framer-font-size": "12px", "--framer-font-weight": "500"}}>Copy link</motion.span></motion.p></React.Fragment>} className={"framer-12s8g2k"} data-framer-name={"Copy link"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"aAv8dZl5s"} style={{"--framer-paragraph-spacing": "0px"}} text={o8IUMdDYJ} transition={transition} verticalAlignment={"center"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-QU5IY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QU5IY .framer-ylyftg { display: block; }", ".framer-QU5IY .framer-lguohf { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-QU5IY .framer-2xxyml { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 16px); overflow: visible; position: relative; width: 16px; }", ".framer-QU5IY .framer-1axe1o3 { flex: none; height: 13px; left: 3px; position: absolute; top: 2px; width: 11px; }", ".framer-QU5IY .framer-12s8g2k { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-QU5IY .framer-lguohf { gap: 0px; } .framer-QU5IY .framer-lguohf > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-QU5IY .framer-lguohf > :first-child { margin-left: 0px; } .framer-QU5IY .framer-lguohf > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 73
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"o8IUMdDYJ":"title"}
 */
const FramerHNiu4TSTg: React.ComponentType<Props> = withCSS(Component, css, "framer-QU5IY") as typeof Component;
export default FramerHNiu4TSTg;

FramerHNiu4TSTg.displayName = "Button/Button Flat";

FramerHNiu4TSTg.defaultProps = {height: 16, width: 73};

addPropertyControls(FramerHNiu4TSTg, {o8IUMdDYJ: {defaultValue: "Copy link", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerHNiu4TSTg, [])